import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const AboutPage = () => {
  return (
    <Layout hero={<HeroImage title="About Us" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col md={10}>
          <Row>
            <Col sm={12}>
              <p>
                Orrcon Steel provides high-quality steel products and solutions. 
                Our product line includes locally manufactured structured tubular steel such as RHS, SHS, CHS and firepipe. 
                In addition, we source an extended range of hot dipped gal pipe, hot rolled structural steel, fencing and roofing to sell through our distribution network.
                We employ over 600 Australians who use their steel industry knowledge and experience to produce and supply reliable steel products.
              </p>
              <p>
                We achieve these results because we manage our company according to the principles of quality, innovation, flexibility and dedication. 
                When you choose to work with us you'll see how we successfully use these principles in our business partnerships.
              </p>
              <p>
                We supply steel, tube and pipe to a diverse customer base including steel fabricators, furniture and trailer body manufacturers, 
                housing and construction companies, pipeline and infrastructure engineering firms and more.
                We have a number of branches, stockists and resellers nation-wide
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <Col xs={10}>
          <div className="title-container mb-5">
            <h1 className="title">
              Why Choose Us?
            </h1> 
          </div>
          <LinkedList items={[
            {
              content: <LinkedListItem 
                title="HEALTH, SAFETY & ENVIRONMENT"
                content="Orrcon Steel takes its Health, Safety and Environment (HSE) responsibilities extremely seriously." />,
              path: '/community/health-safety-environment'
            },
            {
              content: <LinkedListItem 
                title="PARTNERS"
                content="BlueScope and Metalcorp are our primary business partners." />,
              path: '/community/partners'
            },
            {
              content: <LinkedListItem 
                title="HISTORY"
                content="Orrcon Steel was formed from the merger of Welded Tube Mills and Hills Tubing division in October, 2000." />,
              path: '/community/history'
            },
            {
              content: <LinkedListItem 
                title="APPROACH"
                content="We value friendliness, trust, people going out of their way to help each other and banding together to improve Orrcon Steel." />,
              path: '/community/approach'
            },
            {
              content: <LinkedListItem 
                title="QUALITY"
                content="Orrcon Steel's Quality Management System complies with the requirements of ISO 9001." />,
              path: '/community/quality'
            },
            {
              content: <LinkedListItem 
                title="PHILOSOPHY"
                content="We'll see it through is more than a tag line next to our brand. It is a statement that says a lot about our business and the people who work for Orrcon Steel. It is a part of our culture and it reflects our relationships both internaly and externally." />,
              path: '/community/philosophy'
            }
          ]} />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <Col xs={10}>
          <div className="title-container mb-5">
            <h1 className="title">
              Our Expertise
            </h1>
          </div>
          <LinkedList items={[
            {
              content: <LinkedListItem 
                title="DISTRIBUTION"
                content="Orrcon Steel's distribution centres are strategically located in Queensland, New South Wales, Victoria, South Australia and Western Australia, providing a national distribution network." />,
              path: '/community/distribution'
            },
            {
              content: <LinkedListItem 
                title="RANGE"
                content="Our steel range include structural, precision, pipeline and hot rolled structural steel." />,
              path: '/community/range'
            },
            {
              content: <LinkedListItem 
                title="MANUFACTURING"
                content="Orrcon Steel has two Australian manufacturing sites - A structural tubular mill in Brisbane and a precision tubing mill in Adelaide." />,
              path: '/community/manufacturing'
            }
          ]} />
        </Col>
      </Row>
    </Layout>
  )
}

export default AboutPage

export const Head = () => (
  <>
    <title>About Us | Orrcon Steel</title>
    <meta name="description" content="Orrcon Steel provides high-quality steel products and solutions. Our product line includes locally manufactured structured tubular steel such as RHS, SHS, CHS and firepipe." />
  </>
)
